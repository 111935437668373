<template>
  <div class="container my-2 " v-if="getOrder">
    <div class="row mt-5 container">
      <div class="bd-example w-100">

        <div id="request-carousel" class="carousel slide d-flex justify-content-center row" data-ride="carousel"
          style="height:20rem;">

          <div class="carousel-inner w-100 h-100">
            <div class="carousel-item   pt-0 w-100 h-100" v-for="(slide, i) in getOrder.part.images"
              :class="{ active: isOne(i) }">
              <img rel="preload" class="d-block mx-auto w-100 h-100" :src="'https://images.scrapiz.com/' + slide"
                alt="part image">
            </div>
          </div>

          <div class="row">
            <ol class="carousel-indicators">
              <li v-for="(slide, i) in getOrder.part.images" data-target="#request-carousel" :data-slide-to="i"
                :class="{ active: isOne(i) }"></li>
            </ol>
          </div>

        </div>
      </div>


    </div>


    <div class="row row-cols-md-2 row-cols-1 justify-content-between mt-3">

    
      <div :class=" inputClasses" style="direction: ltr">
        <span class="form-control text-center">
          {{ getOrder.part.manufacturer }}
        </span>
        <span class="input-group-text  bw-span">الشركة المصنعة</span>
      </div>
      <div :class=" inputClasses" style=" direction: ltr">
        <span class="form-control text-center">
          {{ getOrder.part.model }}
        </span>
        <span class="input-group-text  bw-span">السيارة</span>
      </div>



      <div :class=" inputClasses" style=" direction: ltr">
        <span class="form-control text-center">
          {{ getOrder.part.year }}
        </span>
        <span class="input-group-text  bw-span">سنة الصنع</span>
      </div>
      <div :class=" inputClasses" style=" direction: ltr">
        <span class="form-control text-center">
          {{ getOrder.part.name }}
        </span>
        <span class="input-group-text  bw-span">اسم القطعة</span>
      </div>
    
    </div>

    <div class="container text-center border border-light rounded font-weight-bold mt-3"
      style="background:#ced4da;font-size: 1.25rem;color: #000000c7;">
      معلومات العرض
    </div>

    <div class="row mt-5 container">
      <div class="bd-example w-100">

        <div id="offer-carousel" class="carousel slide d-flex justify-content-center row" data-ride="carousel"
          style="height:20rem;">

          <div class="carousel-inner w-100 h-100">
            <div
            v-if="getOrder.sellerImages == 0"
            class="carousel-item pt-0 w-100 h-100 active"
          >
            <img
              src="../img/photo.svg"
              alt=""
              class="d-block mx-auto w-100 h-75"
            />
            <h2 class="text-center mt-1">لايوجد صور متاحة</h2>
          </div> 
            <div class="carousel-item   pt-0 w-100 h-100" v-for="(slide, i) in getOrder.sellerImages"
              :class="{ active: isOne(i) }">
              <img rel="preload" class="d-block mx-auto w-100 h-100" :src="'https://images.scrapiz.com/' + slide"
                alt="part image">
            </div>
          </div>

          <div class="row">
            <ol class="carousel-indicators">
              <li v-for="(slide, i) in getOrder.sellerImages" data-target="#offer-carousel" :data-slide-to="i"
                :class="{ active: isOne(i) }"></li>
            </ol>
          </div>

        </div>
      </div>


    </div>
  <div class="row row-cols-md-2 row-cols-1 justify-content-between my-3">


      <div :class=" inputClasses" style=" direction: ltr">
        <span class="form-control text-center price" >
           {{ getOrder.partPrice + " ريال " }} 
        </span>
        <span class="input-group-text  bw-span">قيمة القطعة</span>
      </div>

      <div  :class=" inputClasses" style=" direction: ltr" v-if="!getOrder.pickUp">
        <span class="form-control text-center price">
          {{ getOrder.shippingPrice + "ريال"}} 
        </span>
        <span class="input-group-text  bw-span">قيمة الشحن</span>
      </div>



      <div :class=" inputClasses" style=" direction: ltr">
        <span class="form-control text-center price" >
           {{ getOrder.partPrice + " ريال " }} 
        </span>
        <span class="input-group-text  bw-span">قيمة الدفع عند الاستلام</span>
      </div>








      <div  :class=" inputClasses" style=" direction: ltr" v-if="getOrder.pickUp">
        <span class="form-control text-center">
          {{ getOrder.shipFrom.city }}
        </span>
        <span class="input-group-text  bw-span">موقع التشليح </span>
      </div>


      <div  :class=" inputClasses"  style=" direction: ltr">
        <span class="form-control text-center price">
          {{getOrder.insurancePeriod >0 ? getOrder.insurancePeriod + "يوم":"لايوجد"}} 
        </span>
        <span class="input-group-text  bw-span"> الضمان</span>
      </div>

    <div  :class=" inputClasses"  style=" direction: ltr"  v-if="!getOrder.pickUp">
        <span class="form-control text-center price">
          {{ getOrder.deliveryPeriod + "يوم"}} 
        </span>
        <span class="input-group-text  bw-span"> مدة التوصيل</span>
      </div>

      <div  :class=" inputClasses"  style=" direction: ltr"  >
        <span class="form-control text-center price">
          {{ getOrder.returnPeriod + "يوم"}} 
        </span>
        <span class="input-group-text  bw-span"> مدة الاسترجاع</span>
      </div>

      <div  :class=" inputClasses"  style=" direction: ltr"  >
        <span class="form-control text-center price">
          {{ getOrder.replacePeriod + "يوم"}} 
        </span>
        <span class="input-group-text  bw-span"> مدة الاستبدال</span>
      </div>

    

    </div>

    <div class="container text-center border border-light rounded font-weight-bold my-3"
      style="background:#ced4da;font-size: 1.25rem;color: #000000c7;">
ملاحظات البائع    </div> 



<div class="text-center border  rounded my-3" style="height: 10rem;width: auto;word-wrap: break-word;padding: 1rem;">  
  {{ getOrder.sellerComment ? getOrder.sellerComment.trim() :"لايوجد" }}


</div>

  </div>
</template>


<script>
import { GET_FUUL_ORDER } from "../graphql/queries";

export default {
  data() {
    return {
      userInfo: {
        Fname: "",
        Lname: "",
        Email: "",
      },
      showModal: false,
      stepNo: 1,
      getOrder: null,
      orederID: this.$route.params.orederID,
       
      inputClasses:['input-group','input-group-lg' ,'mt-3','px-2']
    };
  },
  apollo: {
    getOrder: {
      query: GET_FUUL_ORDER,
      variables() {
        return {
          OrderID: this.orederID,
        };
      },
      result(result) {
        this.$store.commit('glopal/isLoading', false);
      },
    },
  },



  methods: {
    OrderStatus(status) {
      if (status == 1) {
        return "تم انشاء الطلب";
      } else if (status == 2) {
        return "تم الشحن";
      } else if (status == 3) {
        return "تم الإستلام";
      } else {
        return "ملغى";
      }
    },

    submit(e, payload) {
      this.$router.push(
        "/CompleteOrder/" + this.getOrder.part._id + "/" + payload._id
      );
    },
    isOne(index) {
      if (index == 0) {
        return true;
      }
    },
    
  
  },
};
</script>

<style scoped>
.price{
  direction:  rtl !important;
}
.textarea{
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
  margin-top: auto;
  height: auto !important;
}
</style>
